import urlParse from 'url-parse';

export default class AssetManager {
  constructor(storage, network) {
    this.storage = storage;
    this.network = network;
  }

  // This encode function is not considering the case the url has its own params
  encode(url) {
    const providers = JSON.parse(this.storage.assetProviders || '[]');
    const compare = urlParse(url);
    for (let i = 0; i < providers.length; i += 1) {
      const provider = urlParse(providers[i].uri);
      if (compare.hostname === provider.hostname) {
        const { descriptor } = providers[i];
        const out = `${url}?${Object.keys(descriptor)
          .map((key) => `${key}=${descriptor[key]}`)
          .join('&')}`;
        return out;
      }
    }

    return url;
  }

  fetchProviders() {
    return this.network.request('GET', '/v1/user/asset_providers').then((payload) => {
      this.storage.assetProviders = JSON.stringify(payload.asset_provider);
      return payload;
    });
  }
}
