import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import translate from '../translate';

function DetailTemplate(props) {
  const { open, title, content, detail, showDetailText, onClose, acceptText, onAccept, t } = props;

  const [expand, setExpand] = useState(false);
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => onClose?.()}>
      <DialogTitle>{translate(t, title)}</DialogTitle>
      <DialogContent>
        <DialogContentText
          color="textPrimary"
          dangerouslySetInnerHTML={{
            __html: translate(t, content),
          }}
        />
        {expand && translate(t, detail)}
      </DialogContent>
      <DialogActions>
        {detail && !expand && (
          <Button onClick={() => setExpand(true)} color="primary">
            {translate(t, showDetailText)}
          </Button>
        )}
        {acceptText && (
          <Button onClick={() => onAccept?.()} color="primary" autoFocus>
            {translate(t, acceptText)}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default withTranslation()(DetailTemplate);
