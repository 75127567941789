export default class Storage {
  constructor(appId, server, websocket, prefix, redirectUrl, loginUrl) {
    this.appId = appId;
    this.server = server;
    this.websocket = websocket;
    this.prefix = prefix;
    this.redirectUrl = redirectUrl;
    this.loginUrl = loginUrl;
    this.user = undefined;
    this.data = new Map();
  }

  getItem(key) {
    if (this.isLocalStorageAvailable) {
      return localStorage.getItem(`${this.prefix}_${key}`);
    }
    return this.data.get(key);
  }

  setItem(key, value) {
    if (this.isLocalStorageAvailable) {
      localStorage.setItem(`${this.prefix}_${key}`, value);
    } else this.data.set(key, value);
  }

  get isLocalStorageAvailable() {
    return window.localStorage !== undefined;
  }

  get accessToken() {
    return this.data.get('access_token');
  }

  set accessToken(value) {
    return this.data.set('access_token', value);
  }

  get refreshToken() {
    return this.getItem('refresh');
  }

  set refreshToken(value) {
    this.setItem('refresh', value);
  }

  get assetProviders() {
    return this.getItem('asset_provider');
  }

  set assetProviders(value) {
    this.setItem('asset_provider', value);
  }

  get currentUser() {
    return this.user;
  }

  set currentUser(value) {
    this.user = value;
  }

  get currentUserTokens() {
    return this.tokens;
  }

  set currentUserTokens(value) {
    this.tokens = value;
  }
}
