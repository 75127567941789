import { Observable } from 'rxjs';

export default class Datapool {
  constructor(blockv) {
    this.blockv = blockv;
  }

  onInventoryUpdating() {
    return new Observable(async (subscriber) => {
      let region = null;
      const syncStart = () => {
        subscriber.next(true);
      };
      const syncFinish = () => {
        subscriber.next(false);
      };
      try {
        region = this.blockv.dataPool.region('inventory');
        region.addEventListener('synchronize.start', syncStart);
        region.addEventListener('synchronize.complete', syncFinish);
        subscriber.next(!region.synchronized);
      } catch (error) {
        console.log(error);
        subscriber.error(error);
      }
      return () => {
        region?.removeEventListener('synchronize.start', syncStart);
        region?.removeEventListener('synchronize.complete', syncFinish);
      };
    });
  }

  getVatom(id) {
    return new Observable(async (subscriber) => {
      let region = null;

      const updateHandler = async (vatomId) => {
        if (vatomId === id) {
          subscriber.next(await region?.getItem(id, false));
        }
      };

      const removeHandler = (vatomId) => {
        if (vatomId === id) {
          subscriber.next(undefined);
        }
      };

      try {
        region = this.blockv.dataPool.region('inventory');
        region.addEventListener('object.updated', updateHandler);
        region.addEventListener('object.removed', removeHandler);
        const vatom = await region.getItem(id, false);
        if (vatom) {
          subscriber.next(vatom);
        } else {
          this.blockv.Vatoms.getUserVatoms([id])
            .then((vatoms) => {
              subscriber.next(vatoms[0]);
            })
            .catch((err) => {
              subscriber.error(err);
            });
        }
      } catch (error) {
        subscriber.error(error);
      }
      return () => {
        region?.removeEventListener('object.updated', updateHandler);
        region?.removeEventListener('object.removed', removeHandler);
      };
    });
  }

  getInventory(
    id = '.',
    filter = () => true,
    sort = (a, b) => {
      if (a.id === b.id) return 0;
      return (
        (a.whenModified > b.whenModified && -1) || (a.whenModified === b.whenModified && 0) || 1
      );
    }
  ) {
    return new Observable(async (subscriber) => {
      let region = null;
      const inventory = new Map();
      const order = [];
      const compare = (left, right) => {
        if (left === right) return 0;
        const vatomLeft = inventory.get(left);
        const vatomRight = inventory.get(right);
        if (!vatomLeft && !vatomRight) return 0;
        if (!vatomLeft) return 1;
        if (!vatomRight) return -1;
        return sort(vatomLeft, vatomRight);
      };

      const updateHandler = async (vatomId) => {
        try {
          if (vatomId !== '.') {
            const vatom = await region?.getItem(vatomId, true);
            if (vatom) {
              if (inventory.has(vatomId)) {
                if (vatom.properties.parent_id === id) {
                  inventory.set(vatomId, vatom);
                  subscriber.next({ inventory, order });
                } else {
                  const index = order.indexOf(vatomId);
                  if (index > -1) {
                    order.splice(index, 1);
                  }
                  inventory.delete(vatomId);
                  subscriber.next({ inventory, order });
                }
              } else if (filter(vatom) && vatom.properties.parent_id === id) {
                inventory.set(vatomId, vatom);
                order.push(vatomId);
                order.sort(compare);
                subscriber.next({ inventory, order });
              }
            }
          }
        } catch (error) {
          console.error(error);
        }
      };

      const removeHandler = (vatomId) => {
        if (inventory.has(vatomId)) {
          const index = order.indexOf(vatomId);
          if (index > -1) {
            order.splice(index, 1);
          }
          inventory.delete(vatomId);
          subscriber.next({ inventory, order });
        }
      };

      try {
        region = this.blockv.dataPool.region('inventory');
        region.addEventListener('object.updated', updateHandler);
        region.addEventListener('object.removed', removeHandler);
        const vatoms = (await region.get(false)) || [];
        vatoms.forEach((vatom) => {
          if (vatom?.properties?.parent_id === id && filter(vatom)) {
            const has = inventory.has(vatom.id);
            inventory.set(vatom.id, vatom);
            if (!has) {
              order.push(vatom.id);
            }
          }
        });
        order.sort(compare);
        if (order.length > 0) {
          subscriber.next({ inventory, order });
        }
        region.get(true).then((data) => {
          data.forEach((vatom) => {
            if (vatom?.properties?.parent_id === id && filter(vatom)) {
              const has = inventory.has(vatom.id);
              inventory.set(vatom.id, vatom);
              if (!has) {
                order.push(vatom.id);
              }
            }
          });
          order.sort(compare);
          subscriber.next({ inventory, order });
        });
      } catch (error) {
        console.log(error);
        subscriber.error(error);
      }
      return () => {
        region?.removeEventListener('object.updated', updateHandler);
        region?.removeEventListener('object.removed', removeHandler);
      };
    });
  }
}
