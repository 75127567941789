import DataPool from '@blockv/sdk/src/internal/DataPool';
import UserManager from '@blockv/sdk/src/client/manager/UserManager';
import UserApi from '@blockv/sdk/src/internal/net/rest/api/UserApi';
import Vatoms from '@blockv/sdk/src/client/manager/Vatoms';
import Activity from '@blockv/sdk/src/client/manager/Activity';
import ActivityApi from '@blockv/sdk/src/internal/net/rest/api/ActivityApi';
import Client from '@blockv/sdk/src/internal/net/Client';
import MultiWebSockets from '@blockv/sdk/src/internal/net/websocket/MultiWebSockets';
import EventEmitter from '@blockv/sdk/src/internal/EventEmitter';
import Platform from '@blockv/sdk/src/internal/net/rest/Platform';
import Store from './store';

export default class Blockv extends EventEmitter {
  constructor(storage, refreshToken) {
    super();
    this.store = new Store(storage);
    this.client = new Client(this);
    this.client.refreshToken = refreshToken;
    this.platform = new Platform(this.client, this.store, false);

    this.dataPool = new DataPool(this);
    this.dataPool.disableSyncV2 = false;

    const userApi = new UserApi(this);
    const activityApi = new ActivityApi(this.client);

    this.Activity = new Activity(activityApi);
    this.WebSockets = new MultiWebSockets(this.store, this.client, this.platform);
    this.UserManager = new UserManager(userApi, this.store);
    this.Vatoms = new Vatoms(this);

    if (this.UserManager.isLoggedIn) {
      this.dataPool.setSessionInfo({ userID: this.store.userID, client: this.client });
    }
  }

  getPlatformIds() {
    return this.platform.getIds();
  }

  updateSession() {
    if (this.UserManager.isLoggedIn) {
      this.dataPool.setSessionInfo({ userID: this.store.userID, client: this.client });
    } else this.dataPool.setSessionInfo(null);
  }
}
