import jwtDecode from 'jwt-decode';
import { Subject } from 'rxjs';

export default class Client {
  constructor(storage) {
    this.storage = storage;
    this.invalidTokenSubject = new Subject();
  }

  async request(method, endpoint, payload = undefined, headers = {}, auth = true) {
    // Check payload type
    let body = null;
    const requestHeaders = { ...headers };
    requestHeaders['App-Id'] = this.storage.appId;
    if (auth) {
      if (!this.validateToken(this.storage.accessToken, 5000)) {
        await this.refreshToken();
      }
      requestHeaders.Authorization = `Bearer ${this.storage.accessToken}`;
    }
    if (!payload) {
      // If no body, make it undefined so that fetch() doesn't complain about having a payload on GET requests
      body = undefined;
    } else if (typeof FormData !== 'undefined' && payload instanceof FormData) {
      // Don't add Content-Type header, fetch() adds it's own, which is required because it specifies the form data boundary
      body = payload;
    } else if (typeof body === 'object') {
      // Convert to JSON
      body = JSON.stringify(payload);
      requestHeaders['Content-Type'] = 'application/json';
    } else {
      // Unknown payload type, assume application/json content type, unless specified in extra headers
      body = payload;
      if (!headers['Content-Type']) requestHeaders['Content-Type'] = 'application/json';
    }

    // try get a response
    let response = null;
    let json = null;

    // Send request
    response = await fetch(this.storage.server + endpoint, {
      method,
      body,
      headers: requestHeaders,
    });

    // Decode JSON
    json = await response.json();

    // Check for server error
    if (json.payload === undefined && json.error === 2051) {
      // Check for the special login locked error
      // We need to pull the timestamp that is in the reponse.message to show when they
      // can login agin

      // HACK: Pull time from original server error string
      const dateString = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/g.exec(response.message);
      const lockedUntil = new Date(dateString);

      // Throw error
      const error = new Error(`Too many login attempts, try again at ${lockedUntil}`);
      error.code = json.error || response.status || 0;
      error.httpStatus = response.status;
      error.requestID = json.request_id;
      error.serverMessage = json.message;
      error.lockedUntil = lockedUntil;
      throw error;
    } else if (json && json.payload === undefined && response.status == 200) {
      // Sometimes, just sometimes, the backend will send a response outside of the usual `payload` param.
      // In this case, just wrap it.
      json = {
        payload: json,
      };
    } else if (json.payload === undefined) {
      // Throw the error returned by the server
      const error = new Error(json.message || 'An unknown server error has occurred');
      error.code = json.error || response.status || 0;
      error.httpStatus = response.status;
      error.requestID = json.request_id;
      error.serverMessage = json.message;
      throw error;
    }

    // Check for main reactor error payload
    if (json.payload && json.payload.main && json.payload.main.error) {
      // Reactor error
      const err = json.payload.main.error.Code || response.status || 0;
      const error = new Error(json.payload.main.error.Msg || 'An unknown server error occurred.');
      error.code = err;
      error.serverMessage = json.payload.main.error.Msg || '';
      error.httpStatus = response.status;
      error.requestID = json.request_id;
      throw error;
    }

    // paging
    if (json.links) {
      return { payload: json.payload, links: json.links };
    }
    // No error, continue
    return json.payload;
  }

  /**
   * Uses the refresh token to fetch and store a new access token from the backend.
   * @private
   */
  refreshToken() {
    // Check if currently fetching an access token
    if (this.tokenFetchPromise) return this.tokenFetchPromise;

    // Start fetching
    this.tokenFetchPromise = this.request(
      'POST',
      '/v1/access_token',
      undefined,
      {
        Authorization: `Bearer ${this.storage.refreshToken}`,
      },
      false
    )
      .then((data) => {
        // Store it
        this.storage.accessToken = data.access_token.token;
        this.tokenFetchPromise = null;
      })
      .catch((error) => {
        // Failed to fetch the token! Keep throwing the error up the chain
        console.warn(error);
        this.tokenFetchPromise = null;
        if (
          error.code === '2049' ||
          error.message.includes('Bad token') ||
          error.message.includes('Invalid token')
        ) {
          this.invalidTokenSubject.next();
          this.storage.refreshToken = '';
        }
        throw error;
      });

    // Return promise
    return this.tokenFetchPromise;
  }

  validateToken(token, timeOffset) {
    try {
      const decodedToken = jwtDecode(token);
      const expirationTime = decodedToken.exp * 1000;
      const nowDate = Date.now();
      if (nowDate + timeOffset > expirationTime) return false;
    } catch (e) {
      return false;
    }
    return true;
  }
}
