import { Subject } from 'rxjs';
import Credentials from '../../credentials';
import Config from '../../config';
import Storage from './storage';
import Client from './network/client';
import getAllUrlParams from '../../util/get-url-params';
import UserManager from './manager/user';
import PublisherManager from './manager/publisher';
import AssetManager from './manager/asset';
import BlockvWrap from './manager/blockv-wrap';
import Inventory from './manager/inventory';
import Action from './manager/action';

class Blockv {
  constructor(appId, redirectUrl, prefix, server, websocket, loginUrl) {
    this.storage = new Storage(appId, server, websocket, prefix, redirectUrl, loginUrl);
    this.network = new Client(this.storage);
    this.user = new UserManager(this.storage, this.network);
    this.publisher = new PublisherManager(this.storage, this.network, this.user.current);
    this.asset = new AssetManager(this.storage, this.network);
    this.wrapped = new BlockvWrap(this.storage, () => {
      return this.network.refreshToken();
    });
    this.inventory = new Inventory(this.wrapped);
    this.action = new Action(this.storage, this.network);
    this.logoutSubject = new Subject();
  }

  get hasValidToken() {
    return this.network.validateToken(this.storage.refreshToken, 6000 * 10);
  }

  login(params) {
    let url = `${this.storage.loginUrl}/?client_id=${this.storage.appId}&redirect_url=${this.storage.redirectUrl}&platform=${Config.primaryPlatform}`;
    if (params != null) {
      const keys = Object.keys(params);
      keys.forEach((key) => {
        url += `&${encodeURI(key)}=${encodeURI(params[key])}`;
      });
    }
    window.open(url, '_self');
  }

  get onInvalidToken() {
    return this.network.invalidTokenSubject;
  }

  get onLogout() {
    return this.logoutSubject;
  }

  async checkForLogin() {
    const params = getAllUrlParams();
    const { refresh_token: refreshToken } = params;
    if (refreshToken) {
      this.setRefreshToken(refreshToken);
      await this.asset.fetchProviders();
      history.replaceState(null, '', location.pathname);
      return params;
    }
    return null;
  }

  setRefreshToken(token) {
    this.storage.refreshToken = token;
    this.wrapped.updateSession();
  }

  logout() {
    this.clearToken();
    this.logoutSubject.next();
    return this.network.request('POST', '/v1/user/logout').finally(() => {
      try {
        this.wrapped.UserManager.logout(true);
      } catch (error) {
        console.log(error);
      }
    });
  }

  clearToken() {
    this.storage.refreshToken = '';
    this.storage.currentUser = undefined;
    this.storage.currentUserTokens = undefined;
  }
}

export default new Blockv(
  Credentials.appId,
  Credentials.redirectUrl,
  Config.prefix,
  Config.serverAddress,
  Config.webSocketServer,
  Config.loginApp
);
