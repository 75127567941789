export default class Action {
  constructor(storage, network) {
    this.storage = storage;
    this.network = network;
  }

  perform(vatomId, action, payload) {
    return this.network.request('POST', `/v1/user/vatom/action/${action}`, {
      'this.id': vatomId,
      ...payload,
    });
  }

  trash(vatomId) {
    return this.network.request('POST', `/v1/user/vatom/trash`, {
      'this.id': vatomId,
    });
  }
}
