import { timer, from } from 'rxjs';
import { share, mergeMap } from 'rxjs/operators';
import Tokens from './token';
import Accounts from './accounts';

export default class CurrentUser {
  constructor(storage, network) {
    this.storage = storage;
    this.network = network;
    this.tokens = new Tokens(storage, network);
    this.accounts = new Accounts(storage, network);
    this.balanceUpdates = timer(0, 10000).pipe(
      mergeMap(() => {
        return from(this.getBalance());
      }),
      share()
    );
  }

  get() {
    return this.network.request('GET', '/v1/user').then((payload) => {
      this.storage.currentUser = payload;
      return payload;
    });
  }

  getBalance() {
    return this.network.request('GET', '/v1/user/balance');
  }

  getBalanceUpdates() {
    return this.balanceUpdates;
  }

  get cached() {
    return this.storage.currentUser;
  }

  getCached() {
    if (this.cached) {
      return Promise.resolve(this.cached);
    }
    return this.get();
  }

  update(payload) {
    return this.network.request('PATCH', '/v1/user', payload).then((result) => {
      this.storage.currentUser = result;
      console.log(result);
      return result;
    });
  }

  uploadAvatar(image) {
    const formData = new FormData();
    formData.append('avatar', image);
    return this.network.request('POST', '/v1/user/avatar', formData);
  }
}
