import React from 'react';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import translate from '../translate';

function AlertTemplate(props) {
  const { open, title, content, onClose, acceptText, onAccept, rejectText, onReject, t } = props;
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => onClose?.()}>
      <DialogTitle>{translate(t, title)}</DialogTitle>
      <DialogContent>
        <DialogContentText
          color="textPrimary"
          dangerouslySetInnerHTML={{
            __html: translate(t, content),
          }}
        />
      </DialogContent>
      <DialogActions>
        {rejectText && (
          <Button onClick={() => onReject?.()} color="primary">
            {translate(t, rejectText)}
          </Button>
        )}
        {acceptText && (
          <Button onClick={() => onAccept?.()} color="primary" autoFocus>
            {translate(t, acceptText)}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default withTranslation()(AlertTemplate);
