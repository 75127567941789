export default class Templates {
  constructor(storage, network) {
    this.storage = storage;
    this.network = network;
  }

  get(pageSize = 50, pageNumber = 0, isPublic = false) {
    return this.network.request(
      'GET',
      `/v1/templates?public=${isPublic}&page[size]=${pageSize}&page[number]=${pageNumber}`
    );
  }

  getById(id) {
    return this.network.request('GET', `/v1/templates/${id}`);
  }

  delete(id) {
    return this.network.request('DELETE', `/v1/templates/${id}`);
  }

  create(payload) {
    return this.network.request('POST', `/v1/templates`, payload);
  }

  update(id, payload) {
    return this.network.request('PATCH', `/v1/templates/${id}`, payload);
  }
}
