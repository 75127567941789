export default class Action {
  constructor(storage, network) {
    this.storage = storage;
    this.network = network;
  }

  getAll(templateId) {
    return this.network.request('GET', `/v1/user/actions/${templateId}`);
  }

  get(id) {
    return this.network.request('GET', `/v1/actions/${id}`);
  }

  delete(id) {
    return this.network.request('DELETE', `/v1/publisher/action`, { id });
  }

  deleteAll(templateId) {
    return this.getAll(templateId).then((actions) =>
      Promise.all(actions.map((action) => this.delete(action.name)))
    );
  }

  update(payload) {
    return this.network.request('PATCH', `/v1/publisher/action`, payload);
  }

  create(payload) {
    return this.network.request('POST', '/v1/publisher/action', payload);
  }
}
