import jwtDecode from 'jwt-decode';

export default class Store {
  constructor(storage) {
    this.storage = storage;
    this.prefix = storage.prefix;
  }

  get server() {
    return this.storage.server;
  }

  set server(address) {}

  set userID(value) {}

  get userID() {
    try {
      const dCode = jwtDecode(this.refreshToken);
      return dCode.user_id;
    } catch (err) {
      console.warn('Decoding failed!');
    }
    return null;
  }

  get appID() {
    return this.storage.appId;
  }

  set appID(value) {}

  get websocketAddress() {
    return this.storage.websocket;
  }

  set websocketAddress(value) {}

  set token(token) {
    this.storage.accessToken = token;
  }

  get token() {
    return this.storage.accessToken;
  }

  set refreshToken(refresh) {
    this.storage.refreshToken = refresh;
  }

  get refreshToken() {
    return this.storage.refreshToken;
  }

  set assetProvider(provider) {
    this.storage.assetProviders = provider;
  }

  get assetProvider() {
    return this.storage.assetProviders;
  }
}
