export default class Vatom {
  constructor(storage, network) {
    this.storage = storage;
    this.network = network;
  }

  emit(templateVariationId, count) {
    const payload = {
      template_variation: templateVariationId,
      num: count,
    };
    return this.network.request('POST', `/v1/vatoms`, payload);
  }
}
