import Datapool from './datapool';

export default class Inventory {
  constructor(blockv) {
    this.datapool = new Datapool(blockv);
  }

  get(id, sort, filter) {
    return this.datapool.getInventory(id, sort, filter);
  }

  get onUpdating() {
    return this.datapool.onInventoryUpdating();
  }
}
