import React from 'react';
import AlertTemplate from './templates/alert-template';
import LoaderTemplate from './templates/loader-template';
import InformTemplate from './templates/inform-template';
import DetailTemplate from './templates/detail-template';
import InputTemplate from './templates/input-template';
import MenuTemplate from './templates/menu-template';

class Alert {
  constructor() {
    this.id = 0;
    this.alerts = new Map();
    this.alertTemplate = AlertTemplate;
    this.loaderTemplate = LoaderTemplate;
    this.informTemplate = InformTemplate;
    this.detailTemplate = DetailTemplate;
    this.inputTemplate = InputTemplate;
    this.menuTemplate = MenuTemplate;
    this.open = {};
    this.loader = this.loader.bind(this);
    this.confirm = this.confirm.bind(this);
    this.notify = this.notify.bind(this);
  }

  setProvider(provider) {
    this.provider = provider;
  }

  getId() {
    const { id } = this;
    this.id += 1;
    return id;
  }

  updateAlerts() {
    const alerts = [];
    this.alerts.forEach((value) => {
      alerts.push(value.alert(value.open));
    });
    this.provider(alerts);
  }

  showAlert(id, alert) {
    this.alerts.set(id, { alert, open: true });
    this.updateAlerts();
  }

  removeAlert(id) {
    const alert = this.alerts.get(id) || {};
    alert.open = false;
    this.alerts.set(id, alert);
    setTimeout(() => {
      this.alerts.delete(id);
    }, 1000);
    this.updateAlerts();
  }

  notify(title, text, actionText) {
    if (this.provider == null) {
      return Promise.reject(new Error('No Alert provider assigned'));
    }
    return new Promise((resolve) => {
      const id = this.getId();
      const Template = this.alertTemplate;
      this.showAlert(id, (open) => (
        <Template
          key={id}
          open={open}
          title={title}
          content={text}
          acceptText={actionText}
          onAccept={() => {
            this.removeAlert(id);
            resolve();
          }}
          onClose={() => {
            this.removeAlert(id);
            resolve();
          }}
        />
      ));
    });
  }

  notifyDetail(title, text, detail, actionText, showDetailText) {
    if (this.provider == null) {
      return Promise.reject(new Error('No Alert provider assigned'));
    }
    return new Promise((resolve) => {
      const id = this.getId();
      const Template = this.detailTemplate;
      this.showAlert(id, (open) => (
        <Template
          key={id}
          open={open}
          title={title}
          content={text}
          acceptText={actionText}
          showDetailText={showDetailText}
          detail={detail}
          onAccept={() => {
            this.removeAlert(id);
            resolve();
          }}
          onClose={() => {
            this.removeAlert(id);
            resolve();
          }}
        />
      ));
    });
  }

  show(factory) {
    const id = this.getId();
    this.showAlert(
      id,
      factory(id, () => {
        this.removeAlert(id);
      })
    );
  }

  confirm(title, text, acceptText, rejectText, cancelable) {
    if (this.provider == null) {
      return Promise.reject(new Error('No Alert provider assigned'));
    }
    return new Promise((resolve) => {
      const id = this.getId();
      const Template = this.alertTemplate;
      this.showAlert(id, (open) => (
        <Template
          key={id}
          open={open}
          title={title}
          content={text}
          acceptText={acceptText}
          onAccept={() => {
            this.removeAlert(id);
            resolve({ action: 'accept' });
          }}
          rejectText={rejectText}
          onReject={() => {
            this.removeAlert(id);
            resolve({ action: 'reject' });
          }}
          onClose={
            cancelable &&
            (() => {
              this.removeAlert(id);
              resolve({ action: 'cancel' });
            })
          }
        />
      ));
    });
  }

  inform(text, time = 3000) {
    const id = this.getId();
    const Template = this.informTemplate;
    const timeout = setTimeout(() => {
      this.removeAlert(id);
    }, time);
    this.showAlert(id, (open) => (
      <Template
        key={id}
        open={open}
        message={text}
        onClose={() => {
          this.removeAlert(id);
          clearTimeout(timeout);
        }}
      />
    ));
  }

  loader(text) {
    const id = this.getId();
    const Template = this.loaderTemplate;
    this.showAlert(id, (open) => <Template key={id} open={open} text={text} />);
    return () => {
      this.removeAlert(id);
    };
  }

  menu(items, anchor) {
    if (this.provider == null) {
      return Promise.reject(new Error('No Alert provider assigned'));
    }
    return new Promise((resolve) => {
      const id = this.getId();
      const Template = this.menuTemplate;
      this.showAlert(id, (open) => (
        <Template
          key={id}
          open={open}
          items={items}
          menuAnchor={anchor}
          onItemClick={(value) => {
            this.removeAlert(id);
            resolve({ value });
          }}
          onClose={() => {
            this.removeAlert(id);
            resolve({ value: null });
          }}
        />
      ));
    });
  }

  confirmInput(
    title,
    text,
    acceptText,
    rejectText,
    cancelable,
    inputLabel,
    initialValue,
    inputType,
    validator
  ) {
    if (this.provider == null) {
      return Promise.reject(new Error('No Alert provider assigned'));
    }
    return new Promise((resolve) => {
      const id = this.getId();
      const Template = this.inputTemplate;
      this.showAlert(id, (open) => (
        <Template
          key={id}
          open={open}
          title={title}
          content={text}
          acceptText={acceptText}
          inputLabel={inputLabel}
          inputType={inputType}
          onAccept={(value) => {
            this.removeAlert(id);
            resolve({ action: 'accept', value });
          }}
          validator={validator}
          rejectText={rejectText}
          initialInput={initialValue}
          onReject={() => {
            this.removeAlert(id);
            resolve({ action: 'reject' });
          }}
          onClose={
            cancelable &&
            (() => {
              this.removeAlert(id);
              resolve({ action: 'cancel' });
            })
          }
        />
      ));
    });
  }
}
export default new Alert();
