import CurrentUser from './current-user';

export default class User {
  constructor(storage, network) {
    this.storage = storage;
    this.network = network;
    this.current = new CurrentUser(storage, network);
  }

  isPublisher(user) {
    return user.system_properties.is_admin;
  }

  consent(kind, version) {
    return this.network.request('PUT', '/v1/user/consent', { kind, version });
  }

  get(id) {
    return this.network.request('GET', `/v1/users/${id}`);
  }
}
