export default class Accounts {
  constructor(storage, network) {
    this.storage = storage;
    this.network = network;
  }

  get() {
    return this.network.request('GET', '/v1/user/accounts');
  }

  delete(id) {
    return this.network.request('DELETE', `/v1/user/accounts/${id}`);
  }

  update(id, payload) {
    return this.network.request('PATCH', `/v1/user/accounts/${id}`, payload);
  }

  add(address, signature, setDefault = false) {
    return this.network.request('POST', '/v1/user/accounts', {
      address,
      signature,
      default: setDefault,
    });
  }

  getNonce(address) {
    return this.network.request('GET', `/v1/user/accounts/nonce?address=${address}`);
  }
}
