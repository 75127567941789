import React from 'react';
import { withTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import translate from '../translate';

function InformTemplate(props) {
  const { open, message, onClose, t } = props;
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={() => onClose?.()}
      message={translate(t, message)}
    />
  );
}
export default withTranslation()(InformTemplate);
