export default class Tokens {
  constructor(storage, network) {
    this.network = network;
    this.storage = storage;
  }

  add(token, tokenType) {
    return this.network.request('POST', '/v1/user/tokens', { token, token_type: tokenType });
  }

  setDefault(tokenId) {
    return this.network.request('PUT', `/v1/user/tokens/${tokenId}/default`);
  }

  delete(tokenId) {
    return this.network.request('DELETE', `/v1/user/tokens/${tokenId}`);
  }

  get() {
    return this.network.request('GET', '/v1/user/tokens').then((payload) => {
      this.storage.currentUserTokens = payload;
      return payload;
    });
  }

  verify(token, tokenType, code) {
    return this.network.request('POST', '/v1/user/verify_token', {
      token,
      token_type: tokenType,
      verify_code: code,
    });
  }

  requestVerificationCode(token, tokenType) {
    const payload = {
      token,
      token_type: tokenType,
    };
    return this.network.request('POST', '/v1/user/reset_token_verification', payload, false);
  }

  get cached() {
    return this.storage.currentUserTokens;
  }
}
