/* eslint-disable react/jsx-filename-extension */
import 'fontsource-roboto';
import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { initReactI18next } from 'react-i18next';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ComponentLoader from './components/component-loader';
import AlertProvider from './util/alert/alert-provider';
import LocalEn from './resources/locales/en.json';
import Blockv from './managers/blockv';
import getAllUrlParams from './util/get-url-params';
import CompositeSubscription from './util/composite-subscription';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0595A5',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FF8E6D',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#006776',
      secondary: '#0595A5',
    },
  },
  typography: {
    button: {
      fontSize: '1rem',
      fontWeight: 300,
    },
  },
  overrides: {
    MuiFab: {
      root: {
        boxShadow: '',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '8px',
      },
    },
    MuiDialogContent: {
      root: {
        [defaultTheme.breakpoints.down('xs')]: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        color: '#0595a5',
        backgroundColor: '#f2f8f4',
        textTransform: 'uppercase',
        textAlign: 'center',
      },
    },
    MuiInputAdornment: {
      filled: {
        marginTop: '0px !important',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'space-between',
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#0595a5',
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        borderRadius: '8px',
        backgroundColor: '#f2f8f4',
        color: '#0595a5',
      },
      underline: {
        '&&:after': {
          borderBottom: 'none',
        },
        '&&&:before': {
          borderBottom: 'none',
        },
      },
      input: {
        paddingTop: '10px',
      },
      multiline: {
        paddingTop: '10px',
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '652px',
      },
      paperFullWidth: {
        width: 'calc(100% - 16px)',
      },
      paperScrollPaper: {
        maxHeight: 'calc(100% - 16px)',
      },
      paper: {
        margin: '8px',
      },
    },
  },
});

const validateLogin = (component) => {
  return (props) => {
    if (!Blockv.hasValidToken) {
      Blockv.clearToken();
      window.location.hash = '/';
      return <></>;
    }
    return component(props);
  };
};
const removeLoader = () => {
  window.removeLoader(300);
};
const Landing = () => <ComponentLoader load={() => import('./features/landing')} />;
const WorkSpace = (props) => {
  const { match, location } = props || {};
  const { params } = match;
  const searchParams = getAllUrlParams(location.search);
  return (
    <ComponentLoader
      props={{
        ...params,
        ...searchParams,
      }}
      load={() => import('./features/workspace')}
      onComplete={removeLoader}
    />
  );
};

const Profile = () => (
  <ComponentLoader load={() => import('./features/profile')} onComplete={removeLoader} />
);
const Editor = (props) => {
  const { match, location } = props || {};
  const { params } = match;
  const searchParams = getAllUrlParams(location.search);
  return (
    <ComponentLoader
      props={{
        ...params,
        ...searchParams,
      }}
      load={() => import('./features/editor')}
      onComplete={removeLoader}
    />
  );
};
const Vatoms = (props) => {
  const { match, location } = props || {};
  const { params } = match;
  const searchParams = getAllUrlParams(location.search);
  return (
    <ComponentLoader
      props={{
        ...params,
        ...searchParams,
      }}
      load={() => import('./features/vatoms')}
      onComplete={removeLoader}
    />
  );
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator', 'querystring', 'cookie', 'htmlTag'],
    },
    resources: {
      en: { translation: LocalEn },
    },
  });

export default class App extends React.Component {
  constructor() {
    super();
    // Setup state
    this.state = {};
    this.subscriptions = new CompositeSubscription();
  }

  componentDidMount() {
    this.subscriptions.add(
      Blockv.onInvalidToken.subscribe(() => {
        if (window.location.hash !== '/') {
          window.location.hash = '/';
        }
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.clear();
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <AlertProvider>
          <Router>
            <Switch>
              <Route path="/profile" component={validateLogin(Profile)} />
              <Route path="/workspace/:tab" component={validateLogin(WorkSpace)} />
              <Route path="/workspace" component={validateLogin(WorkSpace)} />
              <Route path="/edit/:id" component={validateLogin(Editor)} />
              <Route path="/vatoms/:templateVariation" component={validateLogin(Vatoms)} />
              <Route component={Landing} />
            </Switch>
          </Router>
        </AlertProvider>
      </ThemeProvider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
