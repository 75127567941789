export default class PublisherAsset {
  constructor(storage, network, currentUser) {
    this.storage = storage;
    this.network = network;
    this.currentUser = currentUser;
  }

  upload(image, name, prefix, isPublic = false) {
    return this.getFqdn().then((fqdn) => {
      const formData = new FormData();
      formData.append('upload[]', image, name);
      formData.append('prefix', prefix);
      formData.append('public', isPublic ? 1 : 0);
      return this.network.request('POST', `/v1/publishers/${fqdn}/assets`, formData);
    });
  }

  get() {
    return this.getFqdn().then((fqdn) => {
      return this.network.request('GET', `/v1/publishers/${fqdn}/assets`);
    });
  }

  delete(urls) {
    return this.getFqdn().then((fqdn) => {
      return this.network.request('DELETE', `/v1/publishers/${fqdn}/assets`, { urls });
    });
  }

  getFqdn() {
    return this.currentUser.getCached().then((user) => user.system_properties.pub_fqdn);
  }
}
