import React from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import translate from '../translate';

function MenuTemplate(props) {
  const { open, onClose, t, items, menuAnchor, onItemClick } = props;
  return (
    <Menu id="menu" anchorEl={menuAnchor} open={open} onClose={() => onClose()}>
      {items?.map((item) => {
        const { name, value } = item;
        return (
          <MenuItem
            key={value}
            onClick={() => {
              onItemClick?.(value);
            }}
          >
            {translate(t, name)}
          </MenuItem>
        );
      })}
    </Menu>
  );
}

export default withTranslation()(MenuTemplate);
