/* eslint-disable max-classes-per-file */
export default class Face {
  constructor(storage, network) {
    this.storage = storage;
    this.network = network;
  }

  getAll(templateId) {
    return this.network.request('GET', `/v1/publisher/faces/${templateId}`);
  }

  get(id) {
    return this.network.request('GET', `/v1/publisher/face/${id}`);
  }

  delete(id) {
    return this.network.request('DELETE', `/v1/publisher/face/${id}`);
  }

  deleteAll(templateId) {
    return this.getAll(templateId).then((faces) =>
      Promise.all(faces.map((face) => this.delete(face.id)))
    );
  }

  update(payload) {
    return this.network.request('PATCH', `/v1/publisher/face`, payload);
  }

  create(payload) {
    return this.network.request('POST', '/v1/publisher/face', payload);
  }
}

export class RegisterPayload {
  constructor(templateId, displayUrl, constraints, config) {
    this.template = templateId;
    this.display_url = displayUrl;
    this.constraints = constraints;
    this.config = config;
    this.resources = [];
    this.package_url = '.';
  }
}

export class Constraints {
  constructor(platform, viewMode, quality = 'high', other = {}) {
    this.platform = platform;
    this.view_mode = viewMode;
    this.quality = quality;
    Object.assign(this, other);
  }
}
