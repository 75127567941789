export default {
  loginApp: 'https://login.blockv.io/vn/3.1.1',
  serverAddress: 'https://api.bv.vatom.network',
  webSocketServer: 'wss://newws.blockv.io/ws',
  prefix: 'viewer',
  consentKind: 'global',
  consentVersion: 1,
  termsAndConditions: 'https://publisher.blockv.solutions/terms-conditions.pdf',
  privacyPolicy: 'https://publisher.blockv.solutions/privacy-policy.pdf',
  publisherTerms: 'https://publisher.blockv.solutions/developer-terms.pdf',
  primaryPlatform: 'blockv',
};
