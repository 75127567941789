export default class TemplateVariations {
  constructor(storage, network) {
    this.storage = storage;
    this.network = network;
  }

  get(pageSize = 50, pageNumber = 0, isPublic = false) {
    return this.network.request(
      'GET',
      `/v1/template_variations?public=${isPublic}&page[size]=${pageSize}&page[number]=${pageNumber}`
    );
  }

  getAll(isPublic = false) {
    const request = (query, last = []) =>
      this.network.request('GET', query).then((result) => {
        const { links, payload } = result;
        const data = last.concat(payload);
        if (links.next) {
          return request(links.next, data);
        }
        return data;
      });
    return request(`/v1/template_variations?public=${isPublic}&page[size]=${50}&page[number]=${0}`);
  }

  getById(id) {
    return this.network.request('GET', `/v1/template_variations/${id}`);
  }

  delete(id) {
    return this.network.request('DELETE', `/v1/template_variations/${id}`);
  }

  create(payload) {
    return this.network.request('POST', `/v1/template_variations`, payload);
  }

  update(id, payload) {
    return this.network.request('PATCH', `/v1/template_variations/${id}`, payload);
  }
}
