/* eslint-disable max-classes-per-file */
import Template from './template';
import TemplateVariation from './template-variation';
import Face from './face';
import Action from './action';
import PublisherAsset from './publisher-asset';
import Vatom from './vatom';

export default class Publisher {
  constructor(storage, network, currentUser) {
    this.storage = storage;
    this.network = network;
    this.template = new Template(storage, network);
    this.templateVariation = new TemplateVariation(storage, network);
    this.face = new Face(storage, network);
    this.action = new Action(storage, network);
    this.asset = new PublisherAsset(storage, network, currentUser);
    this.vatom = new Vatom(storage, network);
  }

  getCaptcha() {
    return this.network.request('GET', '/v1/captcha');
  }

  upgrade(payload, captcha) {
    return this.network.request('POST', '/v1/publishers', {
      ...payload,
      terms_accepted: true,
      captcha,
    });
  }

  getDepositAccounts() {
    return this.network.request('GET', '/v1/publisher/deposits/accounts');
  }
}

export class UpgradePayload {
  constructor(name, domain, address, country, industry, fqdn, trashUser, password) {
    this.company_name = name;
    this.company_domain = domain;
    this.company_address = address;
    this.company_country = country;
    this.company_industry = industry;
    this.fqdn = fqdn;
    this.trash_user = trashUser;
    this.password = password;
  }
}

export class CaptchaPayload {
  constructor(id, value) {
    this.id = id;
    this.value = value;
  }
}
export class CreateUserPayload {
  constructor(token, tokenType, password, firstName, lastName) {
    this.first_name = firstName;
    this.last_name = lastName;
    this.password = password;
    this.user_tokens = [{ token, token_type: tokenType }];
  }
}
