export default function translate(t, value) {
  const type = typeof value;
  if (type === 'object') {
    return t(value.text, value.variables);
  }
  if (type === 'string') {
    return t(value);
  }
  if (type === 'function') {
    return value(t) || '';
  }
  return '';
}
